<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">{{ $t('globalTrans.all_returnable') }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="contact">Mobile No</label>
                                            <input type="text" id="contact" class="form-control" placeholder="Mobile no" v-model="search.contact">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="tracking_id">{{ $t('globalTrans.order_id') }}</label>
                                            <input type="text" id="tracking_id" class="form-control" placeholder="Order ID" v-model="search.order_id">
                                        </div>
                                    </div>
                                    <div class="col-md-2"  v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="source_hub_id">Source Hub</label>
                                                <v-select name="source_hub_id"
                                                    v-model="search.source_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="destination_hub_id">Destination Hub</label>
                                                <v-select name="destination_hub_id"
                                                    v-model="search.destination_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="start_date">Start Date</label>
                                                <input type="date" id="start_date" v-model="search.start_date" class="form-control">                                                
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="end_date">End Date</label>
                                                <input type="date" id="end_date" v-model="search.end_date" class="form-control">                                                
                                            </div>
                                        </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">{{ $t('globalTrans.status') }}</label>
                                            <v-select name="status"
                                                multiple
                                                v-model="search.status"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= statusList
                                                :placeholder="$t('globalTrans.select')"
                                            /> 
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                        <div class="form-group" style="margin-top:28px;">
                                            <slot>
                                                <label>
                                                    <input type="checkbox" @click="selectAll" v-model="allSelected">
                                                    <p style="display: inline-block; margin-left: 4px; font-size: 15px;">Select All | Selected ({{ totalSelected }} / {{ totalOrder }}) </p>
                                                </label>
                                                <button class="btn btn-info btn-sm ml-2 mr-2" type="submit" @click="showActionPanelModal"><i class="fa fa-check"></i> Take Action</button>
                                            </slot>
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i> Filter</button>
                                            <button class="btn btn-warning btn-sm ml-2" @click.prevent="reload"><i class="fa fa-sync-alt"></i> </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loading">
                                    <div class="table-responsive">
                                        <div style="width:100%; overflow-x:auto;">
                                            <table class="table table-bordered table-striped table-sm" id="orderTable">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="width:13%">{{ $t('globalTrans.order_id') }}</th>
                                                        <th>{{ $t('globalTrans.merchant') }}</th>
                                                        <th>{{ $t('globalTrans.customer') }}</th>
                                                        <th>{{ $t('globalTrans.reference_id') }}</th>
                                                        <th>{{ $t('globalTrans.address') }}</th>
                                                        <th>Main Status</th>  
                                                        <th>Returnable Status</th>  
                                                        <th>{{ $t('globalTrans.date') }}</th>
                                                        <th>{{ $t('globalTrans.price') }}</th>
                                                        <th>{{ $t('globalTrans.collected') }}</th>
                                                        <th>Source</th>
                                                        <th>Destination</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(order,key) in orders" :key="key" class="text-center">
                                                        <td class="order-id">
                                                            <label>
                                                                <input v-model="ordersData" @click="singleSelect(order)" :value="order" type="checkbox" class="order-checkbox" />
                                                                <p>{{ order.id }}</p>
                                                            </label>
                                                        </td>
                                                        <td @click="orderLog(order.logs)">{{ order.merchant.business }}</td>
                                                        <td>{{ order.name }}, {{ order.mobile }}{{ order.mobile_2 ? ', '+ order.mobile_2 : '' }}</td>
                                                        <td>{{ order.ref_id }}</td>
                                                        <td>{{ order.address }}</td>
                                                        <td><span :class="'status-'+order.status">{{ getStatus(order.status) }} </span></td>                                    
                                                        <td><b> {{ getLogLastStatus(order.logs) }}</b></td>                                    
                                                        <td>{{ order.date }}</td>
                                                        <td>{{ order.price }}</td>
                                                        <td>{{ order.collected }}</td>
                                                        <td>{{ order.source ? order.source.name : '' }}</td>
                                                        <td>{{ order.destination ? order.destination.name : '' }}</td>
                                                    </tr>
                                                    <infineLoading v-if="spinner" @distance="1" @infinite="loadData"></infineLoading>
                                                </tbody>
                                            </table>  
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="actionPanelModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Rapid Action on Order</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">                        
                            <input type="text" class="form-control" v-model="barcode.sku" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"/>
                        </div>
                        <div class="form-group"> 
                            <span v-for="(order, index) in orderList" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                {{ order }}<i class="ml-1 fas fa-times" @click="removeRow(index)"></i> 
                            </span>
                        </div>
                        <div class="form-group">   
                                                    
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="status">Status </label>   
                                    <v-select name="status"
                                        v-model="bulk.status"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= takeActionStatusList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group" style="margin-top:28px"> 
                                    Total : <b>{{ orderList.length != 0 ? orderList.length : this.totalSelected }}</b>                            
                                    <button class="ml-1 btn btn-default btn-sm" @click="scanDone" v-if="isScan">Done <i class="fa fa-check"></i></button>
                                    <slot v-if="loading">
                                        <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                    </slot>
                                    <slot v-else>
                                        <button class="ml-1 btn btn-success btn-sm" @click="pdfDownload">PDF <i class="fa fa-download"></i></button>
                                        <button class="ml-1 btn btn-success btn-sm">
                                            <export-excel
                                                :data="dataCustomize"
                                                :fields= "json_fields"
                                                >
                                                Excel <i class="fa fa-download"></i>
                                            </export-excel>
                                        </button>
                                        <button class="ml-1 btn btn-info btn-sm" @click="returnReceive">Submit</button>
                                        <button class="ml-1 btn btn-danger btn-sm" @click="cancelModal">Cancel</button>
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header bg-info text-white">
                      <h5 class="modal-title text-center w-100">Order Log</h5>
                      <button type="button" class="close" aria-label="Close" @click="cancelModal">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <table class="table table-bordered table-sm">
                          <tr>
                              <th style="width:30%">Action By</th>
                              <th>Action</th>
                              <th>Date & Time</th>
                          </tr>
                          <tr v-for="(log, index) in order_logs" :key="index">
                              <td>{{ log.operation_by }}</td>
                              <td>{{ log.operation }}</td>
                              <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                          </tr>
                      </table>                      
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config' 
    import moment from 'moment'    
    import statusHelper from '@/utils/statusMapping'
    import ExportPdf from './ReturnablePdf'
    import infineLoading from 'vue-infinite-loading'
    const excelColumn = {
        'Order ID': 'order_tracking_id',
        'Merchant': 'order_merchant_name',
        'Name': 'order_contact_name',
        'Mobile': 'order_contact_number',
        'Date': 'order_date',
        'Price': 'order_price',
        'Collected': 'order_collected',
        'Instruction': 'order_instruction',
        'Reason': 'order_reason',
        'Status': 'order_status'
    }
    export default {
        name:'Returnable',
        data () {
            return {            
                loading: false,                 
                orderLogModal: false, 
                allSelected: false,                
                actionPanelModal:false,
                isExcelDownload: false,
                isScan: false,
                totalSelected: 0,
                totalOrder: 0,
                spinner: true,
                orders: [],
                orderId: '',
                search: {
                    merchant_id: '',
                    order_id: '',
                    contact: '',
                    ref_id: '',
                    status: '',
                    source_hub_id: '',
                    destination_hub_id: '',
                    start_date: moment().subtract(7,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                },
                orderList: [],
                ordersData: [],
                order_ids: [],
                order_logs: [],
                printorders:[],
                bulk:{
                    order_ids: [],
                    status: ''
                },
                barcode:{
                    sku: ''
                },
                page: 1,
                key:'',
                buttonName:'',
                takeActionStatusList: [],
                statusList: [
                    { id:15, text: 'Partial Delivered'},
                    { id:19, text: 'Exchange'},
                    { id:20, text: 'Cancel'},
                    { id:21, text: 'On way to RTM'},
                    { id:22, text: 'Received by RTM'},
                    { id:23, text: 'On way to Source HUB'},
                    { id:24, text: 'Return Recevied by Source HUB'},
                    { id:25, text: 'Return On way to Merchant'}
                ]
            }
        },
        components: {
            infineLoading: infineLoading
        },
        // created () {
        //     if (this.$route.query) {
        //        Object.assign(this.search, this.$route.query)
        //     } 
        //     this.searchData()
        // },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            stateReload () {
                return this.$store.state.stateReload
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                if (this.isExcelDownload) {
                    if (this.printorders.length > 0) {
                        this.printorders.map(item => {
                            item.order_tracking_id = item.tracking_id
                            item.order_merchant_name= item.merchant.business,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_date= item.date,
                            item.order_price= item.price,
                            item.order_collected= item.collected,
                            item.order_instruction= item.instruction,
                            item.order_reason= item.reason,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.printorders
                    } else if (this.orders.length > 0) {
                        this.orders.map(item => {
                            item.order_tracking_id = item.tracking_id
                            item.order_merchant_name= item.merchant.business,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_date= item.date,
                            item.order_price= item.price,
                            item.order_collected= item.collected,
                            item.order_instruction= item.instruction,
                            item.order_reason= item.reason,
                            item.order_status= this.getStatus(item.status)

                            return Object.assign({}, item)
                        })
                        return this.orders
                    } else {
                        return []
                    }
                }
                return []
            }
        },
        methods:{
            searchData () {
                this.page = 1
                this.totalOrder = 0
                this.orders = []
                this.loadData()
            },
            loadData($state) { 
                let vm = this; 
                this.search = this.authUser.role_id == 7 ? Object.assign({}, this.search, { destination_hub_id: this.authUser.hub_id, page: this.page }) :  Object.assign({}, this.search, { page: this.page })
                config.getData('return/returnable', this.search)
                .then(res => {
                    return res
                }).then(response => {
                    this.totalOrder += response.data.data.length
                    response.data.data.map(item => {
                        vm.orders.push(item)
                    })
                    if (this.totalOrder < response.data.total) {
                        this.spinner = true
                        $state.loaded()
                    } else {
                        this.spinner = false
                    }
                });
                this.page = this.page + 1;
            },
            selectAll: function() {   
                this.takeAction = false                
                this.ordersData = [];
                this.orderList = [];
                if (!this.allSelected) {
                    for (var order in this.orders) {
                        this.ordersData.push(this.orders[order])
                        this.orderList.push(this.orders[order].id);
                    }
                    this.totalSelected = this.orderList.length
                } else {
                    this.totalSelected = 0
                }
            },
            singleSelect(order) {
                this.takeAction = false
                if (!this.orderList.includes(order.id)) {
                    this.orderList.push(order.id)
                } else {
                    this.orderList.splice(this.orderList.indexOf(order.id), 1)
                }     
                this.totalSelected = this.orderList.length          
                this.allSelected = false;                
            },
            keepOrder() { 
                let skuList = this.barcode.sku.split(" ")
                if(skuList.length > 1) {
                    skuList.map(item => {
                        this.orderList.push(item);
                    })
                } else {
                    this.orderList.push(this.barcode.sku); 
                }
                this.isScan = true  
                this.barcode.sku = ''
            },
            removeRow(index){
                this.isScan = true
                this.orderList.splice(index, 1)
            },
            orderLog (logs) {
                this.order_logs = logs 
                this.orderLogModal = true
            },
            cancelModal () {
                this.barcode.sku = this.bulk.status = ''
                this.printorders = this.bulk.order_ids = this.orderList = this.takeActionStatusList = []  
                this.totalOrder = 0
                this.allSelected =  this.orderLogModal = this.actionPanelModal = false
            },
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(item => item.id == status)
                return typeof tmpStatus !== 'undefined' ? tmpStatus.text : ''
            },
            getLogLastStatus (logs) {
                const status = logs[0].status
                const tmpStatus = this.$store.state.statusList.find(item => item.id == status)
                return typeof tmpStatus !== 'undefined' ? tmpStatus.text : ''
            },
            reload () {
                this.totalOrder = 0
                this.search = Object.assign({}, {
                    merchant_id: '',
                    order_id: '',
                    contact: '',
                    ref_id: '',
                    status: '',
                    source_hub_id: '',
                    destination_hub_id: '',
                    start_date: moment().subtract(30,'d').format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD')
                })
            },
            async returnReceive(){    
                this.loading = true 
                // const toStatus = this.search.status == 21 ? 22 : 21
                const params = { from_status: this.search.status[0], to_status: this.bulk.status, order_ids: this.orderList}       
                const response = await config.postData('/return/receive', params)
                this.loading = false
                if (response.success){
                    this.totalOrder = 0
                    this.order_ids = []
                    this.searchData()
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    }) 
                } else {
                    this.order_ids = []
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    }) 
                }                             
            },
            pdfDownload() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.returnPdfDetails(base64Logo, this.printorders)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },            
            showActionPanelModal () {
                this.isScan = true
                this.actionPanelModal = true
                this.takeActionStatusList = this.search.status ? statusHelper.getReturnTakeActionStatus(this.search.status) : ''
                document.body.classList.add("modal-open");
            },
            async scanDone () {
                this.loading = true
                const response = await config.postData('/order/scaned-order', { 'order_ids': this.orderList })                        
                this.loading = false                                          
                this.isScan = false                                          
                if (response.status === 200) {                                           
                    this.printorders = response.data;
                    this.isExcelDownload = true
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Data not Found !!',
                        color: '#dc3545'
                    })
                }
            },
            async bulkAction(){ 
                this.loading = true 
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                const skuList = this.barcode.sku.split(" ")
                if (!this.isScan && skuList.length > 0) {
                    skuList.forEach((value) => {
                        const orderId = value.split("-")[2]
                        this.bulk.order_ids.push(orderId)
                    })
                }
                const response = await config.postData('/order/bulk-action', this.bulk)
                this.loading = false 
                if (response.status == 201){   
                    this.orders = response.data
                    this.bulk.status = ''
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
        }
    }
</script>
