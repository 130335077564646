// import JsBarcode from 'jsbarcode' 
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn'

const returnPdfDetails = async (base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    pdfMake.vfs = pdfFontsBn.pdfMake.vfs 
    pdfMake.fonts = {
      Kalpurush: {
        normal: 'Kalpurush.ttf',
        bold: 'Kalpurush.ttf',
        italics: 'Kalpurush.ttf',
        bolditalics: 'Kalpurush.ttf'
      }
    }
    
    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, height: 70, width: 70, style: 'logo', alignment: 'left' },
      { text: 'Total Order : ' + vm.length, style: 'org', alignment: 'left' }
    ]

    pdfContent.push({ text: '', style: 'space' })

    const allRows = [
      [
        { text: 'Order ID', style: 'th', alignment: 'center', bold: true },
        { text: 'Date', style: 'th', alignment: 'center', bold: true },
        { text: 'Customer', style: 'th', alignment: 'center', bold: true },
        { text: 'Price', style: 'th', alignment: 'center', bold: true },
        { text: 'Collected', style: 'th', alignment: 'center', bold: true },
        { text: 'Instruction', style: 'th', alignment: 'center', bold: true },
        { text: 'Reason', style: 'th', alignment: 'center', bold: true },
        { text: 'Status', style: 'th', alignment: 'center', bold: true }
      ]
    ]

    vm.forEach(item => {
        const orderStatus = getOrderStatus(item.status)
        allRows.push([
            { text: item.tracking_id, alignment: 'center', style: 'td' },
            { text: item.date, alignment: 'center', style: 'td' },
            { text: item.name + ', '+ item.mobile, alignment: 'center', style: 'td' },
            { text: item.price, alignment: 'center', style: 'td' },
            { text: item.collected, alignment: 'center', style: 'td' },
            { text: item.instruction, alignment: 'center', style: 'td' },
            { text: item.reason, alignment: 'center', style: 'td' },
            { text: orderStatus, alignment: 'center', style: 'td', color: getStatusColor(item.status) }
        ])
    })

    pdfContent.push({
        table: {
            headerRows: 1,
            widths: ['20%', '10%', '15%', '10%', '10%', '12%', '13%', '10%'],
            body: allRows
        }
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      footer: function (currentPage, pageCount) {
        return {
          table: {
            body: [
                [
                  { text: "Page " + currentPage.toString() + ' of ' + pageCount, alignment: 'right', style: 'normalText', /*margin: [0, 0, 0, 0]*/ }
                ]
            ]
          }
        };
      },
      defaultStyle: {
        font: 'Kalpurush'
      },
      styles: {
        th: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: 8,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        logo: {
          margin: [0, 0, 0, 10]
        },
        normalText: {
          border: 0
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    var win = window.open('', '_blank')
    pdfMake.createPdf(docDefinition).open({}, win)
  } catch (error) {
    if (error) {
      console.log('err => ', error)
    }
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}

function getOrderStatus (status) {
    const tmpStatus = Store.state.statusList.find(el => el.id == status)
    return tmpStatus.text
  }

function getStatusColor (status) {
    if (status == 22) {
      return [0, 0, 0]
    } else if (status == 21 || status == 23 || status == 25) {
      return [255, 193, 7]
    } else if (status == 24 || status == 26) {
      return [128,0,128]
    }
  }

export default {
    returnPdfDetails
}
