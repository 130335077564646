import Store from '@/store'
// import i18n from '@/i18n'

function getMappingStatus (status) {
    const statusList = Store.state.userStatusList 
    const role = Store.state.authUser.role_id  
    
    if (role <= 2) {
        return statusList
    }
    
    let tmpStatusList = []

    if (status == 1) {
        tmpStatusList = statusList.filter(item => item.id == 2 || item.id == 3)
    } else if (status == 3) {
        tmpStatusList = statusList.filter(item => item.id == 4)
    } else if (status == 4) {
        tmpStatusList = statusList.filter(item => item.id == 9 || item.id == 10 || item.id == 17)
    } else if (status == 9) {
        tmpStatusList = statusList.filter(item => item.id == 11|| item.id == 5)
    } else if (status == 10) {
        tmpStatusList = statusList.filter(item => item.id == 5)
    } else if (status == 5) {
        tmpStatusList = statusList.filter(item => item.id == 7 || item.id == 17 || item.id == 18 || item.id == 20 || item.id == 37 || item.id == 38)
    } else if (status == 6) {
        tmpStatusList = statusList.filter(item => item.id == 4)
    } else if (status == 7) {
        tmpStatusList = statusList.filter(item => item.id == 7 || item.id == 11 || item.id == 14 || item.id == 15 || item.id == 17 || item.id == 18 || item.id == 19 || item.id == 20)
    } else if (status == 11) {
        tmpStatusList = statusList.filter(item => item.id == 14 || item.id == 15 || item.id == 17 || item.id == 18 || item.id == 19 || item.id == 20)
    } else if (status == 12) {
        tmpStatusList = statusList.filter(item => item.id == 20 || item.id == 17 || item.id == 18)
    } else if (status == 17) {
        tmpStatusList = statusList.filter(item => item.id == 18 || item.id == 7 || item.id == 20 || item.id == 17 || item.id == 37 || item.id == 38)
    } else if (status == 18) {
        tmpStatusList = statusList.filter(item => item.id == 6 || item.id == 7 || item.id == 11 || item.id == 18 || item.id == 20 || item.id == 37 || item.id == 38)
    } else if (status == 13) {
        tmpStatusList = statusList.filter(item => item.id == 15)
    } else if (status == 21) {
        tmpStatusList = statusList.filter(item => item.id == 22)
    } else if (status == 22) {
        tmpStatusList = statusList.filter(item => item.id == 23)
    } else if (status == 23) {
        tmpStatusList = statusList.filter(item => item.id == 24)
    } else if (status == 24) {
        tmpStatusList = statusList.filter(item => item.id == 25)
    } else if (status == 16) {
        tmpStatusList = statusList.filter(item => item.id == 14)
    } else if (status == 30) {
        tmpStatusList = statusList.filter(item => item.id == 6)
    } else if (status == 15 || status == 19 || status == 20) {
        tmpStatusList = statusList.filter(item => item.id == 21 || item.id == 24)
    } else if (status == 37) {
        tmpStatusList = statusList.filter(item => item.id == 5)
    } else if (status == 38) {
        tmpStatusList = statusList.filter(item => item.id == 20 || item.id == 4)
    }

    return tmpStatusList
}

function getTakeActionStatus (status) {
    const statusList = Store.state.userStatusList 
    const role = Store.state.authUser.role_id  
    
    if (role <= 2) {
        return statusList
    }

    let tmpStatusList = [] 

    if (status == 1) {
        tmpStatusList = statusList.filter(item => item.id == 2 || item.id == 3)
    } else if (status == 3) {
        tmpStatusList = statusList.filter(item => item.id == 4)
    } else if (status == 4) {
        tmpStatusList = statusList.filter(item => item.id == 10)
    } else if (status == 9) {
        tmpStatusList = statusList.filter(item => item.id == 11|| item.id == 5)
    } else if (status == 10) {
        tmpStatusList = statusList.filter(item => item.id == 5)
    } else if (status == 5) {
        tmpStatusList = statusList.filter(item => item.id == 5 || item.id == 7 || item.id == 37 || item.id == 38)
    } else if (status == 7) {
        tmpStatusList = statusList.filter(item => item.id == 7 || item.id == 11 || item.id == 14)
    } else if (status == 11) {
        tmpStatusList = statusList.filter(item => item.id == 14)
    } else if (status == 21) {
        tmpStatusList = statusList.filter(item => item.id == 22)
    } else if (status == 22) {
        tmpStatusList = statusList.filter(item => item.id == 23)
    } else if (status == 23) {
        tmpStatusList = statusList.filter(item => item.id == 24)
    } else if (status == 24) {
        tmpStatusList = statusList.filter(item => item.id == 25)
    } else if (status == 16) {
        tmpStatusList = statusList.filter(item => item.id == 14)
    }  else if (status == 17 || status == 18) {
        tmpStatusList = statusList.filter(item => item.id == 7)
    } else if (status == 30) {
        tmpStatusList = statusList.filter(item => item.id == 6)
    } else if (status == 15 || status == 19 || status == 20) {
        tmpStatusList = statusList.filter(item => item.id == 21 || item.id == 24)
    } else if (status == 37) {
        tmpStatusList = statusList.filter(item => item.id == 5)
    } else if (status == 38) {
        tmpStatusList = statusList.filter(item => item.id == 20 || item.id == 4)
    }
    
    return tmpStatusList
}

function getReturnTakeActionStatus (status) {
    const statusList = Store.state.userStatusList 
    const role = Store.state.authUser.role_id  
    
    if (role <= 2) {
        return statusList
    }

    let tmpStatusList = []

    if (Array.isArray(status) && (status.includes(15) || status.includes(16) || status.includes(19) || status.includes(20))) {
        tmpStatusList = statusList.filter(item => item.id == 21 || item.id == 24)
    } else if (status == 21) {
        tmpStatusList = statusList.filter(item => item.id == 22)
    } else if (status == 22) {
        tmpStatusList = statusList.filter(item => item.id == 23)
    } else if (status == 23) {
        tmpStatusList = statusList.filter(item => item.id == 24)
    } else if (status == 24) {
        tmpStatusList = statusList.filter(item => item.id == 25)
    }
    
    return tmpStatusList
}

export default {
    getMappingStatus,
    getTakeActionStatus,
    getReturnTakeActionStatus
}
